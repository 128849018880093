import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { brown } from "../style"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <h1>not found</h1>
    <p>
      sorry, but this page doesn't exist.
      <br />
      <br />
      if you want, you can take a look at our inventory{" "}
      <Link
        css={{
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
          color: brown,
        }}
        to="/"
      >
        here
      </Link>
      .
    </p>
  </Layout>
)

export default NotFoundPage
